import * as React from "react";
import Hamburger from "@mui/icons-material/MenuSharp";
import { Box, Link, Popover } from "@mui/material";
import "./HamburgerMenu.css";

const HamburgerMenu = ({ toggleHamburger, setToggle, anchor, setAnchor }) => {
  const handleClick = () => {
    if (!toggleHamburger) {
      setToggle((e) => (e = true));
      // setAnchor((e) => (e = `p`));
    } else {
      setToggle((e) => (e = false));
    }
  };
  if (toggleHamburger) {
    return (
      <>
        <Hamburger
          sx={{
            fontSize: "3em",
            color: `white`,
            display: { xs: "block", sm: "block", md: "none" },
            ml: "0.5em",
          }}
          onClick={handleClick}
        />
        <Popover
          sx={{ top: "-3em", width: "50%" }}
          // anchorEl={anchor}
          // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          // transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={handleClick}
          onClose={handleClick}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: "rgba(0,0,0,0.9)",
              position: "fixed",
              justifyContent: "center",
              left: "0%",
              top: "3.25em",
              minWidth: "10em",
              width: "40%",
              height: "50%",
              color: "#fff",
              border: "3px solid blue",
            }}
          >
            <Link sx={{ color: "#fff" }} className="mobile-links" href="/">
              Home
            </Link>
            <Link
              sx={{ color: "#fff" }}
              className="mobile-links"
              href="https://saintedroots.org/donate"
            >
              Donate to a village
            </Link>
            <Link
              sx={{ color: "#fff" }}
              className="mobile-links"
              href="https://saintedroots.org/"
            >
              Main Site
            </Link>
          </Box>
        </Popover>
      </>
    );
  } else {
    return (
      <>
        <Hamburger
          sx={{
            fontSize: "3em",
            color: `white`,
            display: { xs: "block", sm: "block", md: "none" },
            ml: "0.5em",
          }}
          onClick={handleClick}
        />
      </>
    );
  }
};
export default HamburgerMenu;
