import React from "react";
import axios from "axios";
import {
  Link,
  Stepper,
  Button,
  Container,
  Typography,
  Step,
  StepContent,
  Box,
  StepLabel,
  TextField,
  Card,
  Select,
  InputLabel,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import QuestionCard from "./QuestionCard";
import RadioButtonGroup from "./RadioButtonGroup";
import Buffalo from "./assets/Buffalo.jpg";
import Leopard from "./assets/Leopard.jpg";
import Lion from "./assets/Lion.jpg";
import Giraffe from "./assets/Giraffe.jpg";

function MyStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [submitMessage, setSubmitMessage] = React.useState("");
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = [
    "Welcome",
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
  ];

  const animalImages = [Buffalo, Leopard, Lion, Giraffe];

  const textFieldStyles = {
    "& .MuiInputLabel-root": {
      fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.5rem" },
    },
    fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.5rem" },
    // marginBlock: "0.75em",
    maxWidth: "100em",
    width: "80%",
    marginBlock: "0.75em",
    marginInline: "10%",
  };

  const helperTextStyles = {
    "& .MuiFormHelperText-root": {
      fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.5rem" },
      marginInline: "10%",
    },
    fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.5rem" },
    marginInline: "10%",
  };

  const checkboxStyles = {
    marginInlineStart: { xs: "2%", sm: "9%", md: "10%" },
  };

  const schema = [
    yup.object({
      title: yup.string().required("Title is required"),
      firstname: yup
        .string()
        .required("First name is a required field.")
        .min(2, "Your first name must be more than 1 character")
        .max(30, "Your first name is too long, no more than 30 characters"),
      lastname: yup
        .string()
        .required("Last name is a required field.")
        .min(2, "Last name should be more than 1 character")
        .max(30, "Last name should be no longer than 30 characters"),
      email: yup.string().required().email(),
      jobtitle: yup
        .string()
        .max(50, "Job title should be no longer than 50 characters"),
      volunteer: yup.bool(),
      consent: yup
        .bool()
        .required(
          "You must consent to processing to enter the quiz, otherwise we can't contact you"
        )
        .oneOf(
          [true],
          "You must consent to processing if you wish to submit your information and enter the quiz."
        ),
      // areasOfInterest: yup.array(),
    }),
    yup.object({ zimquizq1: yup.string() }),
    yup.object({ zimquizq2: yup.string() }),
    yup.object({ zimquizq3: yup.string() }),
    yup.object({ zimquizq4: yup.number() }),
  ];

  const interests = [
    "Sustainable Farming",
    "Biodiversity",
    "Personal Growth",
    "Architecture & Infrastructure",
    "Culture",
    "Tourism",
    "Setting up a Sainted Roots Community",
  ];
  const questions = [
    {
      name: "Who was the first President of Zimbabwe?",
      options: [
        "Robert Mugabe",
        "Canaan Banana",
        "Josiah Zion Gumede",
        "Ian Smith",
      ],
      correctAnswer: "Canaan Banana",
    },
    {
      name: "What does Zimbabwe mean in Shona?",
      answerGiven: "",
    },
    {
      name: "Which of these are considered Zimbabwe’s “Big Five” Animals?",
      options: ["Buffalo", "Leopard", "Lion", "Giraffe"],
      correctAnswer: ["Buffalo", "Leopard", "Lion"],
    },
    {
      name: "What is the population of Zimbabwe?",
      answerGiven: "",
      correctAnswer: "15301333",
    },
  ];

  const shonaRegex = /(house[s]*[\w|\s]*stone)|(stone[\w|\s]*house[s]*)/gm;

  const currentStepSchema = schema[activeStep];

  const {
    handleSubmit,
    control,
    reset,
    setFocus,
    watch,
    formState,
    formState: { isDirty, isSubmitSuccessful },
    trigger,
  } = useForm({
    defaultValues: {
      title: "",
      firstname: "",
      lastname: "",
      email: "",
      volunteer: false,
      jobtitle: "",
      interests: "",
      communications: false,
      consent: false,
      zimquizq1: "",
      zimquizq2: "",
      zimquizq3: false,
      zimquizq4: "",
    },
    resolver: yupResolver(currentStepSchema),
  });

  const checkboxVolunteer = watch("volunteer");

  const isStepOptional = (step) => {
    return step >= 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    let isValid = false;
    switch (activeStep) {
      case 0:
        isValid = await trigger([
          "title",
          "firstname",
          "lastname",
          "email",
          "volunteer",
          "jobtitle",
          "interests",
          "communications",
          "consent",
        ]);
        break;
      default:
        isValid = true;
        break;
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep);

      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const submitToHubSpot = async (data) => {
    const animalArray = getCheckboxSelections(questions[2].options);

    let score = 0;

    if (data.zimquizq1 === questions[0].correctAnswer) {
      score += 10;
    }
    if (shonaRegex.test(data.zimquizq2.toLowerCase())) {
      score += 10;
    }
    if (arrayEquivalentCheckbox(animalArray, questions[2].correctAnswer)) {
      score += 5;
    }

    populationScorer(data.zimquizq4, questions[3].correctAnswer);

    function arrayEquivalentCheckbox(selectedOptions, correctOptions) {
      return (
        Array.isArray(selectedOptions) &&
        Array.isArray(correctOptions) &&
        selectedOptions.length === correctOptions.length &&
        selectedOptions.every((val, index) => val === correctOptions[index])
      );
    }

    function populationScorer(givenAnswer, actualAnswer) {
      const difference = givenAnswer - actualAnswer;
      const positiveDifference = Math.round(Math.abs(difference));

      if (positiveDifference <= 100000) {
        score += 50;
      } else if (positiveDifference <= 1000000) {
        score += 25;
      } else if (positiveDifference <= 2000000) {
        score += 10;
      } else if (positiveDifference <= 5000000) {
        score += 5;
      } else if (positiveDifference <= 10000000) {
        score += 2;
      }
    }

    const interestsSelected = [];
    const animalsSelected = [];

    function getCheckboxSelections(options) {
      const selected = [];
      for (let item of options) {
        if (data[item]) {
          selected.push(item);
        }
      }
      return selected;
    }

    const interestString = getCheckboxSelections(interests).join(";");
    const animalString = getCheckboxSelections(questions[2].options).join(";");

    const payload = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: data.firstname,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: data.lastname,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: data.email,
        },
        {
          objectTypeId: "0-1",
          name: "volunteer",
          value: data.volunteer,
        },
        {
          objectTypeId: "0-1",
          name: "jobtitle",
          value: data.jobtitle,
        },
        {
          objectTypeId: "0-1",
          name: "areasofinterest",
          value: interestString,
        },
        {
          objectTypeId: "0-1",
          name: "title",
          value: data.title,
        },
        {
          objectTypeId: "0-1",
          name: "zimquiz_q1",
          value: data.zimquizq1,
        },
        {
          objectTypeId: "0-1",
          name: "zimquiz_q2",
          value: data.zimquizq2,
        },
        {
          objectTypeId: "0-1",
          name: "zimquiz_q3",
          value: animalString,
        },
        {
          objectTypeId: "0-1",
          name: "zimquiz_q4",
          value: data.zimquizq4,
        },
        {
          objectTypeId: "0-1",
          name: "zimquizscore",
          value: score,
        },
      ],
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: data.consent,
          text: "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: data.communications,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    };

    try {
      let response = await axios.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/26083370/532ef624-9d5b-468e-b3cc-ecd7879979ae",
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        if (score >= 30) {
          setSubmitMessage(
            (newMessage) =>
              (newMessage = (
                <>
                  <Typography variant="h1">
                    Congratulations you passed!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", textAlign: "center" }}
                  >
                    You scored {score} points which means you passed. You will
                    receive your raffle ticket number shortly to your email!
                  </Typography>
                </>
              ))
          );
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setSubmitMessage(
            (newMessage) =>
              (newMessage = (
                <>
                  <Typography variant="h1" sx={{ textAlign: "center" }}>
                    Sorry, you have not won this time!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", textAlign: "center" }}
                  >
                    Unlucky this time, you didn't pass the quiz you scored{" "}
                    {score} points
                  </Typography>
                </>
              ))
          );
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } catch (error) {
      console.error(error.response);
      alert(`Error: ${error.response.status}`);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card
            sx={{
              padding: { xs: "0.75em", md: "1.25em" },
              margin: { md: "0 auto" },
              width: { xs: "100%", md: "80%" },
            }}
          >
            <Typography variant="h2">
              Fill in the form below to complete the Zimbabwe quiz and get a
              raffle ticket.
            </Typography>
            {/* <Typography
              variant="body1"
              sx={{ textAlign: "center", marginInlineStart: "0em" }}
            >
              If you'd like to buy a raffle ticket{" "}
              <Link href="/raffle">enter here</Link>
            </Typography> */}
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginInlineStart: "0em" }}
            >
              Enter your details below
            </Typography>
            <Box display="flex" flexDirection="column">
              <Controller
                name="title"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <FormControl
                    sx={{ marginInline: { xs: "10%", sm: "0em" } }}
                    error={!!errors.title}
                  >
                    <InputLabel
                      sx={{ ...textFieldStyles, ...helperTextStyles }}
                      id="title"
                    >
                      Title
                    </InputLabel>

                    <Select
                      sx={{ ...textFieldStyles }}
                      {...field}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="title"
                    >
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                      <MenuItem value="Miss">Miss</MenuItem>
                      <MenuItem value="Dr">Dr</MenuItem>
                      <MenuItem value="Dame">Dame</MenuItem>
                      <MenuItem value="Sir">Sir</MenuItem>
                    </Select>
                    <FormHelperText
                      sx={{ ...helperTextStyles }}
                      error={!!errors.title}
                    >
                      {!!errors.title
                        ? errors.title?.message
                        : "Please select an option"}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="firstname"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    sx={{ ...textFieldStyles, ...helperTextStyles }}
                    {...field}
                    id="firstname"
                    label="First Name"
                    variant="outlined"
                    placeholder="Sainted"
                    error={!!errors.firstname}
                    helperText={errors.firstname?.message}
                  />
                )}
              />
              <Controller
                name="lastname"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    sx={{ ...textFieldStyles, ...helperTextStyles }}
                    {...field}
                    id="lastname"
                    label="Last Name"
                    variant="outlined"
                    placeholder="Roots"
                    error={!!errors.lastname}
                    helperText={errors.lastname?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    sx={{ ...textFieldStyles, ...helperTextStyles }}
                    {...field}
                    id="email"
                    label="email *"
                    variant="outlined"
                    placeholder="roots.zimbabwe@gmail.com"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Controller
                id="volunteer"
                name="volunteer"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlLabel
                      sx={{ ...checkboxStyles }}
                      onChange={onChange}
                      control={<Checkbox name="volunteer" checked={value} />}
                      label="Would you like to volunteer with us?"
                    ></FormControlLabel>
                  );
                }}
              />
              {checkboxVolunteer && (
                <Controller
                  name="jobtitle"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <TextField
                      sx={{ ...textFieldStyles, ...helperTextStyles }}
                      {...field}
                      label="Profession"
                    />
                  )}
                />
              )}

              <FormGroup
                sx={{ flex: "1 1 100%" }}
                // name="interests"
                // onChange={onChange}
                // value={value}
              >
                <FormLabel sx={{ ...checkboxStyles, marginBlock: "0.75em" }}>
                  Which of the following are you interested in?
                </FormLabel>
                {interests.map((item) => (
                  <Controller
                    name={item}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ ...checkboxStyles }}
                        key={item}
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={value}
                            id={item}
                            // name={fieldName}
                          />
                        }
                        label={item}
                      ></FormControlLabel>
                    )}
                  />
                ))}
              </FormGroup>
              <Typography variant="h4">Privacy Notice</Typography>
              <Typography variant="body1">
                Sainted Roots is committed to protecting and respecting your
                privacy, and we’ll only use your personal information to
                administer your account and to provide the products and services
                you requested from us.
              </Typography>
              <Typography variant="body1">
                From time to time, we would like to contact you about our
                products and services, as well as other content that may be of
                interest to you. If you consent to us contacting you for this
                purpose, please tick below to say how you would like us to
                contact you:
              </Typography>
              <Controller
                id="communications"
                name="communications"
                control={control}
                render={({ checked, field: { onChange, value } }) => {
                  return (
                    <FormControlLabel
                      sx={{ ...checkboxStyles }}
                      onChange={onChange}
                      control={
                        <Checkbox name="communications" checked={value} />
                      }
                      label="I agree to receive a newsletter from Sainted Roots and the associated Kait's newsletter."
                    ></FormControlLabel>
                  );
                }}
              />
              <Typography variant="body1">
                In order to provide you the content requested, we need to store
                and process your personal data. If you consent to us storing
                your personal data for this purpose, please tick the checkbox
                below.
              </Typography>
              <Controller
                id="consent"
                name="consent"
                control={control}
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  return (
                    <>
                      <FormControlLabel
                        sx={{ ...checkboxStyles }}
                        onChange={onChange}
                        control={<Checkbox name="consent" checked={value} />}
                        label="I agree to allow Sainted Roots to store and process my personal data."
                        error={!!errors.consent}
                      ></FormControlLabel>
                      <FormHelperText error={!!errors.consent}>
                        {errors.consent?.message}
                      </FormHelperText>
                    </>
                  );
                }}
              />
              <Typography variant="body1">
                You can unsubscribe from these communications at any time. For
                more information on how to unsubscribe, our privacy practices,
                and how we are committed to protecting and respecting your
                privacy, please review our Privacy Policy.
              </Typography>
            </Box>
          </Card>
        );
      case 1:
        return (
          <QuestionCard question={questions[0].name}>
            <Controller
              name="zimquizq1"
              control={control}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <RadioButtonGroup
                  onChange={onChange}
                  value={value}
                  id="zimquizq1"
                  // control={control}

                  fieldName="zimquizq1"
                  fields={questions[0].options}
                />
              )}
            />
          </QuestionCard>
        );
      case 2:
        return (
          <div>
            <QuestionCard question={questions[1].name}>
              <Controller
                name="zimquizq2"
                control={control}
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => (
                  <TextField
                    sx={{ ...textFieldStyles }}
                    value={value}
                    onChange={onChange}
                    id="zimquizq2"
                    // label="Enter your answer here..."
                    variant="outlined"
                    // placeholder="Enter you answer here..."
                    // error={!!errors.zimquiz_q2}
                    // helperText={errors.zimquiz_q2?.message}
                  />
                )}
              />
            </QuestionCard>
          </div>
        );
      case 3:
        return (
          <QuestionCard question={questions[2].name}>
            <FormHelperText sx={{ fontSize: { xs: "1rem", md: "2rem" } }}>
              Choose 3
            </FormHelperText>
            <Controller
              name="zimquizq3"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  // name="interests"
                  // onChange={onChange}
                  // value={value}
                >
                  {questions[2].options.map((item, index) => (
                    <Controller
                      name={item}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          sx={{ flex: "1 1 30%", marginLeft: "10%" }}
                          key={item}
                          control={
                            <>
                              <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                                <img
                                  alt="Animal see checkbox label"
                                  src={animalImages[index]}
                                  style={{ maxWidth: "20em" }}
                                />
                              </Box>
                              <Checkbox
                                onChange={onChange}
                                checked={value}
                                id={item}
                                // name={fieldName}
                              />
                            </>
                          }
                          label={item}
                        ></FormControlLabel>
                      )}
                    />
                  ))}
                </FormGroup>
              )}
            />
          </QuestionCard>
        );
      case 4:
        return (
          <div>
            <QuestionCard question={questions[3].name}>
              <Controller
                name="zimquizq4"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    sx={{ ...textFieldStyles }}
                    {...field}
                    id="zimquizq4"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                )}
              />
            </QuestionCard>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Container maxWidth="100%" sx={{}}>
      <form
        style={{ maxWidth: "100%", minWidth: "50%" }}
        id="quiz"
        onSubmit={handleSubmit(submitToHubSpot)}
      >
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          sx={{ display: { md: "none" }, float: "left", marginRight: "2em" }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <>
                <Step sx={{ display: "flex" }} key={index} {...stepProps}>
                  <StepLabel sx={{ flexWrap: "wrap" }} {...labelProps}>
                    {label}
                  </StepLabel>
                  {index === activeStep ? (
                    <Box
                      sx={{
                        display: { xs: "flex", md: "none" },
                        maxWidth: "100%",
                        flexDirection: "column",
                        // ml: 1,
                        // mr: 1,
                      }}
                    >
                      {getStepContent(index)}
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handlePrevious}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {isStepOptional(activeStep) && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                        >
                          Skip
                        </Button>
                      )}
                      {activeStep === steps.length - 1 ? (
                        <Button type="submit" form="quiz">
                          Finish
                        </Button>
                      ) : activeStep === 0 ? (
                        <Button onClick={handleNext}>Start Quiz</Button>
                      ) : (
                        <Button onClick={handleNext}>Next</Button>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Step>
              </>
            );
          })}
        </Stepper>

        <Stepper sx={{ display: { xs: "none", md: "flex" } }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <>
                <Step sx={{ display: "flex" }} key={index} {...stepProps}>
                  <StepLabel
                    sx={{ flexWrap: "wrap", marginRight: "0.25em" }}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              </>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <>
            {submitMessage}
            <Typography
              variant="body1"
              sx={{ mt: 2, mb: 1, fontSize: "1.5rem", textAlign: "center" }}
            >
              We hope you enjoyed the Sainted Roots Zimfest quiz.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Restart</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                pt: 2,
              }}
            >
              {getStepContent(activeStep)}
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handlePrevious}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button type="submit" form="quiz">
                  Finish
                </Button>
              ) : activeStep === 0 ? (
                <Button onClick={handleNext}>Start Quiz</Button>
              ) : (
                <Button onClick={handleNext}>Next</Button>
              )}
            </Box>
          </>
        )}
      </form>
      <div></div>
    </Container>
  );
}

export default MyStepper;
