import React from "react";
import { Box, Link } from "@mui/material";

const NavBar = () => {
  const linkStyles = {
    color: "white",
    padding: "0.25em 0.5em",
    "&:hover": {
      color: "#23c23e",
      transition: "color 1s ease-out",
    },
  };
  return (
    <Box
      aria-label="navigation-bar"
      sx={{
        display: {
          xl: "flex",
          lg: "flex",
          md: "flex",
          sm: "none",
          xs: "none",
        },
        fontSize: "1.5rem",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: "1 1 50%",
        maxHeight: "4em",
        // maxWidth: "50%",
        width: "50%",
        margin: "0 0.25em 0 0",
      }}
    >
      <Box>
        <Link
          underline="always"
          sx={{
            ...linkStyles,
          }}
          href="/"
          className="main-nav"
        >
          Home
        </Link>
        <Link
          underline="always"
          sx={{
            ...linkStyles,
          }}
          href="https://saintedroots.org/donate"
          className="main-nav"
        >
          Donate
        </Link>
        <Link
          underline="always"
          sx={{
            ...linkStyles,
          }}
          href="https://saintedroots.org/"
          className="main-nav"
        >
          Main Site
        </Link>
      </Box>
    </Box>
  );
};

export default NavBar;
