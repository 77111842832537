// import logo from './logo.svg';

import "./App.scss";
import React from "react";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App App-link">
      <Router>
        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/raffle"></Route>
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
