import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MyStepper from "./MyStepper";

function Home() {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1">The Zimbabwe Sainted Roots Quiz</Typography>
      <MyStepper activeStep={1} />
    </Box>
  );
}

export default Home;
