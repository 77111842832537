import React from "react";
import { Box, Typography } from "@mui/material";
import "./footer.scss";

function Footer() {
  return (
    <footer>
      {/* <Container maxWidth="100%"> */}
      <Box display="flex" justifyContent="center" alignItems="flex-end">
        <Typography type="body1">
          Developed by &copy; Slick Blue Dragon &reg;
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
