import React from "react";
import { Card, Typography, Box } from "@mui/material";

function QuestionCard({ question, children }) {
  return (
    <Card
      sx={{
        padding: { xs: "1em", md: "2.5em" },
        width: { xs: "100%", md: "80%" },
      }}
      className="questionCard"
    >
      <Typography variant="h2">{question}</Typography>
      {children}
    </Card>
  );
}

export default QuestionCard;
