import React from "react";
import { Container, Link, Box } from "@mui/material";
import Menu from "@mui/icons-material/Menu";
import NavBar from "./NavBar";
import HamburgerMenu from "./HamburgerMenu";
import LogoMobile from "./assets/sainted_roots_logo_mobile.png";
import LogoTablet from "./assets/sainted_roots_logo_tablet.png";
import LogoDesktop from "./assets/sainted_roots_logo_desktop.png";
import "./header.scss";
function Header() {
  const [toggleHamburger, setToggle] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  return (
    <header>
      {/* <Container maxWidth="100%"> */}
      <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
        <img
          style={{ minHeight: "40px" }}
          alt="logo"
          src={LogoMobile}
          srcSet={`${LogoMobile} 300w, ${LogoTablet} 600w, ${LogoDesktop} 1280w`}
        ></img>
        <HamburgerMenu
          toggleHamburger={toggleHamburger}
          anchor={anchor}
          setToggle={setToggle}
          setAnchor={setAnchor}
        ></HamburgerMenu>
        <NavBar></NavBar>
      </Box>
      {/* </Container> */}
    </header>
  );
}

export default Header;
