import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function Theme({ children }) {
  const theme = createTheme({
    typography: {
      fontFamily: "Questrial sans-serif",
      h1: {},
    },
    components: {
      MuiCardRoot: { padding: "0.75em" },
    },
  });

  theme.typography.h1 = {
    marginBlockStart: "0.75em",
    color: "#000",
    [theme.breakpoints.up("md")]: {
      marginBlockEnd: "0.75em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  };

  theme.typography.body1 = {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      marginInlineStart: "0em",
    },
  };
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
